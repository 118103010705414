import React from 'react'
import { Form, Icon, Input, Select, Button, Divider } from 'antd';

const Option = Select.Option;
const { TextArea } = Input;

class CampaignForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {brands: ['All Bar One', 'Harvester', 'Miller & Carter']}
        this.saveAndContinue = this.saveAndContinue.bind(this);
    }

    saveAndContinue(e){
        e.preventDefault();

        // Validate the form
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.props.nextStep();
                this.props.saveData(values);
            }
        });
    }

    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <h1>Create a new campaign</h1>
                <Divider />

                <Form.Item label="Which brand is this campaign for?" help="">
                    {getFieldDecorator('brand', {
                        rules: [{ required: true, message: 'Please select a brand' }],
                        initialValue: this.props.formData.brand
                    })(
                        <Select>
                            {this.state.brands.map((brand, index) => {
                                return <Option key={brand+index} value={brand}>{brand}</Option>;
                            })}

                        </Select>
                    )}
                </Form.Item>

                <Form.Item label="Please provide your email address">
                    {getFieldDecorator('clientEmail', {
                        rules: [{type: "email", message: "Please enter a valid email address"}, { required: true, message: 'Please enter an email address' }],
                        initialValue: this.props.formData.clientEmail
                    })(
                        <Input prefix={<Icon type="inbox" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" />
                    )}
                </Form.Item>

                <Form.Item label="Campaign Name" help="For example: Easter Campaign 2019">
                    {getFieldDecorator('campaignName', {
                        rules: [{ required: true, message: 'Please enter a campaign name' }],
                        initialValue: this.props.formData.campaignName
                    })(
                        <Input type="email" placeholder="" />
                    )}
                </Form.Item>

                <Form.Item label="In one sentence, what do you want this campaign to do?" help="For example: I am looking to raise awareness of our new Spring menu, driving people to visit a pre-launch event with 50% off discount. ">
                    {getFieldDecorator('campaignOverview', {
                        rules: [{ required: true, message: 'Please provide an overview of the campaign' }],
                        initialValue: this.props.formData.campaignOverview
                    })(
                        <TextArea rows={4} />
                    )}
                </Form.Item>

                <Button type="primary" htmlType="button" onClick={this.saveAndContinue}>Next</Button>
            </Form>
        );
    }
}

export default Form.create()(CampaignForm);
