import React from 'react';
import Wrapper from '../components/Wrapper'
import { Steps, Row, Col } from 'antd';

import CampaignForm from '../components/Forms/CampaignForm'
import ChannelSelector from '../components/Forms/ChannelSelector'
import Confirmation from '../components/Forms/Confirmation'

import './create.less'

const Step = Steps.Step;

export default class CreatePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            steps: [{
                title: 'Create a new campaign',
                description: 'Set the campaign wide options',
            }, {
                title: 'Add channels',
                content: 'Second-content',
                description: 'Add the components of the campaign',
            }, {
                title: 'Confirmation',
                content: 'Last-content',
                description: 'Confirm the brief and send to agencies',
            }],
            campaignData: {},
            channelData: {}
        };
    }

    next = () => {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev = () => {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    saveCampaignData = (data) => {
        this.setState({campaignData: data })
    }

    saveChannelData = (data) => {
        this.setState({channelData: data })
    }

    getStepContent(){
        switch (this.state.current) {
            case 0:
                return <CampaignForm
                    nextStep={this.next}
                    saveData={this.saveCampaignData}
                    formData={this.state.campaignData}
                />
            case 1:
                return <ChannelSelector
                    nextStep={this.next}
                    prevStep={this.prev}
                    saveData={this.saveChannelData}
                    formData={this.state.channelData}
                />
            case 2:
                return <Confirmation
                    nextStep={this.next}
                    prevStep={this.prev}
                    formData={this.state}
                />
            default:
                return <p>Error</p>
        }
    }

    render() {
        const { current } = this.state;
        const content = this.getStepContent();

        return (
            <Wrapper>
                <Row className="stepWrapper">
                    <Col span={12} offset={6}>
                        <Steps current={current}>
                            {this.state.steps.map(item =>
                                <Step key={item.title} title={item.title} description={item.description} />
                            )}
                        </Steps>
                    </Col>
                </Row>
                <Row className="formWrapper">
                    <Col span={12} offset={6}>
                        <div className="steps-content">
                            {content}
                        </div>
                    </Col>
                </Row>
            </Wrapper>
        );
    }
}

