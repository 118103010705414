import React from 'react'
import {Button, Form, Divider, Avatar, List, Input} from 'antd';
const { TextArea } = Input;

class Confirmation extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.formData;
        this.previous = this.previous.bind(this);
        this.saveAndContinue = this.saveAndContinue.bind(this);
    }

    saveAndContinue() {
        alert('END OF DEMO: The data would now be sent to the server for processing')
    }

    previous(e){
        e.preventDefault();
        this.props.prevStep();
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <h1>Confirmation</h1>
                <Divider />
                <p>Please check and confirm the details below. You can then either save the campaign to continue editing later, or send the campaign to agencies</p>

                <List
                    itemLayout="horizontal"
                    dataSource={Object.keys(this.state.channelData)}
                    locale={{emptyText: "No channels"}}
                    renderItem={key => (
                        <List.Item>
                            <List.Item.Meta
                                title={this.state.channelData[key].name}
                                description={`Dispatch date: ${this.state.channelData[key].data.dispatchDate.format('Do MMMM YYYY')}, Sending brief to: Connecting Element`}
                                avatar={<Avatar size="large" icon="mail" />}
                            />
                        </List.Item>
                    )}
                />

                <Form.Item
                    label="Are there any further details to provide for this campaign as a whole?"
                >
                    {getFieldDecorator('campaignDetails', {
                        rules: [{required: false}]
                    })(
                        <TextArea rows={4}/>
                    )}
                </Form.Item>


                <Button type="danger" htmlType="button" onClick={this.previous}>Previous</Button>
                <Button type="dashed" htmlType="button" onClick={this.saveAndContinue}>Save for later</Button>
                <Button type="primary" htmlType="button" onClick={this.saveAndContinue}>Send to agencies</Button>
            </Form>
        );
    }
}

export default Form.create()(Confirmation);
