import React from 'react'
import { Row, Col, Form, Icon, Input, Select, Switch, DatePicker, Button } from 'antd';
import './EmailFom.less'
import moment from "moment-business-days";

const Option = Select.Option;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

class EmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            audiences: ['All customers', 'Engaged customers', 'Non-engaged customers', 'Family', 'Non-family', 'Has app', 'Does not have app', 'Has live booking', 'Does not have live booking', 'English BUNs', 'Scottish BUNs', 'Male', 'Female', 'Guests who’ve dined within the last 3 months', 'Guests who’ve dined within the last 6 months', 'Occasion interests e.g. xmas, Mother’s/Father’s Day', 'Sports flag', 'Vegan flag'],
            messages: ['Apology/oops', 'Brand awareness/story', 'Event promotion', 'General booking', 'New menu promotion', 'Occasion booking e.g. xmas, Mother’s/Father’s Day', 'Product highlight', 'Site closure (permanent)', 'Site opening (brand new site)', 'Site re-opening', 'Site refurbishment (temp. closure)', 'Voucher push'],
            assetsAvailable: true,
            hasOffer: false,
            dates: {}
        }
        this.saveAndContinue = this.saveAndContinue.bind(this);
        this.calculateDates = this.calculateDates.bind(this);

    }

    saveAndContinue(e){
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // Pass the data back to the parent
                this.props.formData.name = values.emailName;
                this.props.formData.data = values;
                this.props.saveChannel(this.props.formData);
                // Close the modal
                this.props.hideModal();
            }
        });
    }

    toggleAssets = () => {
        this.setState({
            assetsAvailable: !this.state.assetsAvailable,
        });
    }
    toggleOffer = () => {
        this.setState({
            hasOffer: !this.state.hasOffer,
        });
    }

    cancel = (e) => {
        e.preventDefault();
        this.props.hideModal();
    }

    disabledDates(current) {
        let customDate =  moment().businessAdd(15,'days').format('YYYY-MM-DD')
        return current && current < moment(customDate, "YYYY-MM-DD");
    }

    calculateDates(date){

        if(date) {
            let mocks = moment(date.businessSubtract(13)._d).format('Do MMMM YYYY');
            let feedback = moment(date.businessSubtract(9)._d).format('Do MMMM YYYY');
            let html = moment(date.businessSubtract(5)._d).format('Do MMMM YYYY');

            this.setState({
                dates: {
                    mocks,
                    feedback,
                    html
                }
            });
        } else {
            this.setState({
                dates: { }
            });
        }
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <Row className="emailForm">
                    <Col span={12} className="formCol">
                        <Form.Item label="Email Name" help="For example: Campaign Message 1">
                            {getFieldDecorator('emailName', {
                                rules: [{ required: true, message: 'Please enter an email name' }],
                                initialValue: this.props.formData.data.emailName
                            })(
                                <Input prefix={<Icon type="inbox" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" />
                            )}
                        </Form.Item>

                        <Form.Item label="When would you like the email to launch?" help="Must be at least 15 working days in advance">
                            {getFieldDecorator('dispatchDate', {
                                rules: [{ required: true, message: 'Please enter a dispatch date' }],
                                initialValue: this.props.formData.data.dispatchDate
                            })(
                                <DatePicker format="Do MMMM YYYY" disabledDate={this.disabledDates} onChange={this.calculateDates} />
                            )}
                        </Form.Item>

                        {Object.keys(this.state.dates).length !== 0 &&
                            <ul className="dateDisplay">
                                <li key={1}><strong>Proofs expected by:</strong> {this.state.dates.mocks}</li>
                                <li key={2}><strong>Feedback needed by:</strong> {this.state.dates.feedback}</li>
                                <li key={3}><strong>HTML needed by:</strong> {this.state.dates.html}</li>
                            </ul>
                        }

                        <Form.Item label="When should the email be re-sent to non-openers?" help="Leave blank for no resend">
                            {getFieldDecorator('resendDate', {
                                rules: [{ required: false}],
                                initialValue: this.props.formData.data.resendDate
                            })(
                                <DatePicker format="Do MMMM YYYY" disabledDate={this.disabledDates} />
                            )}
                        </Form.Item>

                        <Form.Item label="Who are the key audience(s) for this email?">
                            {getFieldDecorator('keyAudience', {
                                rules: [{ required: true, message: 'Please select a key audience' }],
                                initialValue: this.props.formData.data.keyAudience
                            })(
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                >
                                    {this.state.audiences.map((audience, index) => {
                                        return <Option key={audience+index} value={audience}>{audience}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="What are the key message(s) of this email?">
                            {getFieldDecorator('keyMessage', {
                                rules: [{ required: true, message: 'Please select a key message' }],
                                initialValue: this.props.formData.data.keyMessage
                            })(
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                >
                                    {this.state.messages.map((message, index) => {
                                        return <Option key={message+index} value={message}>{message}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Are there any sites we should exclude for this campaign?"
                            help="Please provide BUN numbers where possible"
                        >
                            {getFieldDecorator('exclusions', {
                                rules: [{required: false}],
                                initialValue: this.props.formData.data.exclusions
                            })(
                                <TextArea rows={4}/>
                            )}
                        </Form.Item>

                    </Col>

                    <Col span={12} className="formCol">

                        <Form.Item
                            label="Do assets already exist for this campaign?"
                            labelCol={{span: 18}}
                            wrapperCol={{span: 6}}
                        >
                            {getFieldDecorator('assetsAvailable', {
                                rules: [{ required: false }],
                                initialValue: this.props.formData.data.assetsAvailable
                            })(
                                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked onChange={this.toggleAssets}/>
                            )}
                        </Form.Item>
                        {(this.state.assetsAvailable === true || this.props.formData.data.assetsAvailable === true) > 0 &&
                        <Form.Item
                            label="Please provide a link to download the assets"
                        >
                            {getFieldDecorator('assetLink', {
                                rules: [{required: false}],
                                initialValue: this.props.formData.data.assetLink
                            })(
                                <Input prefix={<Icon type="link" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder=""/>
                            )}
                        </Form.Item>
                        }

                        {(this.state.assetsAvailable === false || this.props.formData.data.assetsAvailable === false) > 0 &&
                        <Form.Item
                            label="Let us know if something is being created (and by whom)"
                        >
                            {getFieldDecorator('assetLink', {
                                rules: [{required: false}],
                                initialValue: this.props.formData.data.assetLink
                            })(
                                <TextArea rows={4}/>
                            )}
                        </Form.Item>
                        }

                        <Form.Item
                            label="Does this email have an associated offer?"
                            labelCol={{span: 18}}
                            wrapperCol={{span: 6}}
                        >
                            {getFieldDecorator('hasOffer', {
                                rules: [{ required: false }],
                                initialValue: this.props.formData.data.hasOffer
                            })(
                                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={this.toggleOffer}/>
                            )}
                        </Form.Item>
                        {(this.state.hasOffer === true || this.props.formData.data.hasOffer === true) > 0 &&
                            <div>
                                <Form.Item
                                    label="Offer title"
                                    labelCol={{span: 8}}
                                    wrapperCol={{span: 16}}
                                >
                                    {getFieldDecorator('offerTitle', {
                                        rules: [{required: true}],
                                        initialValue: this.props.formData.data.offerTitle
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Campaign ID"
                                    labelCol={{span: 8}}
                                    wrapperCol={{span: 16}}
                                >
                                    {getFieldDecorator('campaignId', {
                                        rules: [{required: true}],
                                        initialValue: this.props.formData.data.campaignId
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Valid dates"
                                    labelCol={{span: 8}}
                                    wrapperCol={{span: 16}}
                                >
                                    {getFieldDecorator('validDates', {
                                        rules: [{required: true}],
                                        initialValue: this.props.formData.data.validDates
                                    })(
                                        <RangePicker />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label="Terms and conditions"
                                    placeholder="Important: Please ensure that these terms and conditions are confirmed before entering. If there are errors, it is unlikely that we have the information needed to spot them, this is the responsibility of the brand."
                                >
                                    {getFieldDecorator('terms', {
                                        rules: [{required: true}],
                                        initialValue: this.props.formData.data.terms
                                    })(
                                        <TextArea cols={4} />
                                    )}
                                </Form.Item>
                            </div>
                        }

                        <Form.Item
                            label="Is there anything else you’d like to add?"
                            help="Such as price band information or CTA links"
                        >
                            {getFieldDecorator('furtherInformation', {
                                rules: [{required: false}],
                                initialValue: this.props.formData.data.furtherInformation
                            })(
                                <TextArea rows={4}/>
                            )}
                        </Form.Item>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="danger" htmlType="button" onClick={this.cancel}>Cancel</Button>
                        <Button type="primary" htmlType="submit" onClick={this.saveAndContinue}>Save</Button>
                    </Col>
                </Row>
        </Form>
        );
    }
}

export default Form.create()(EmailForm);
