import React from 'react'
import {Form, Modal, Select, Button, Row, Col, List, Avatar, Divider } from 'antd';

import EmailForm from './Channel/EmailForm'
import './ChannelSelector.less';
import cloneDeep from 'lodash/cloneDeep';

const Option = Select.Option;
const uuidv1 = require('uuid/v1');
/*
    This component should allow a channel to be created
    The channel will be stored in the channelData and displayed in a list
    The configure button will open a modal with the channel form within it
    The modal save button will validate the form and pass the data back to this component for storing in state
    Finally the next button is clicked and data is saved to the index state for API dispatch in the final step

*/
class ChannelSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: ['Email', 'Web', 'Social', 'App', 'PPC'],
            channelData: this.props.formData,
            modalVisible: false,
            modalChannelObject: {}
        }

        this.saveAndContinue = this.saveAndContinue.bind(this);
        this.saveChannel = this.saveChannel.bind(this);
        this.previous = this.previous.bind(this);
        this.handleChannelDelete = this.handleChannelDelete.bind(this);
        this.handleChannelDuplicate = this.handleChannelDuplicate.bind(this);
    }

    saveAndContinue(e){
        e.preventDefault();
        // Check we have at least 1 channel
        if(Object.keys(this.state.channelData).length > 0) {
            // Save this and navigate to the next step
            this.props.saveData(this.state.channelData);
            this.props.nextStep();
        } else {
            alert('No channels created');
        }
    }

    addChannel(type){
        return {
            key: uuidv1(),
            type: type,
            name: '',
            data: {}
        }
    }

    saveChannel(channelObject){
        let currentState = this.state.channelData;
        currentState[channelObject.key] = channelObject;
        this.setState({
            channelData: currentState
        }, () => {
            // Save this state to the parent
            this.props.saveData(this.state.channelData);
        })
    }

    previous(){
        this.props.saveData(this.state.channelData);
        this.props.prevStep();
    }

    showModal = (channelObject) => {
        this.setState({
            modalVisible: true,
            modalChannelObject: channelObject
        });
    }

    hideModal = () => {
        this.setState({
           modalVisible: false,
        });
    }

    handleChannelSelection = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // Get the channel data and set it here
                const channelObject = this.addChannel(values.newChannel)
                // Open the modal
                this.showModal(channelObject);
            }
        });
    }

    handleChannelEdit = (e) => {
        // Load the modal form with the data from the key
        const key = e.target.getAttribute('data-key');
        // Get the channel data and set it here
        const channelObject = this.state.channelData[key];
        // Open the modal
        this.showModal(channelObject);
    }

    handleChannelDelete = (e) => {
        if (window.confirm("Are you sure you want to delete this channel?")) {
            // Load the modal form with the data from the key
            const key = e.target.getAttribute('data-key');
            let newState = this.state.channelData;
            delete newState[key];

            this.setState({
                channelData: newState
            }, () => {
                // Save this state to the parent
                this.props.saveData(this.state.channelData);
            })
        }
    }

    handleChannelDuplicate = (e) => {
        // Load the modal form with the data from the key
        const key = e.target.getAttribute('data-key');
        let newState = this.state.channelData;
        // Get a new key and set this data
        let newKey = uuidv1();
        newState[newKey] = cloneDeep(newState[key]);
        newState[newKey].name = newState[newKey].name + ' (copy)';
        newState[newKey].key = newKey;
        this.setState({
            channelData: newState
        }, () => {
            // Save this state to the parent
            this.props.saveData(this.state.channelData);
        })

    }

    render(){
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="ChannelSelector">
                <h1>Add channels to your campaign</h1>
                <Divider />
                <Row className="addChannelRow">
                    <Col>
                        <Form layout="inline" onSubmit={this.handleChannelSelection}>

                            <Form.Item label="Channel" help="">
                                {getFieldDecorator('newChannel', {
                                    rules: [{ required: true, message: 'Please select a channel' }],
                                    initialValue: this.props.formData.brand
                                })(
                                    <Select style={{width: "200px"}}>
                                        {this.state.channels.map((channel, index) => {
                                            return <Option key={channel+index} value={channel}>{channel}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="circle"
                                    icon="plus"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Active channels</h4>
                        <List
                            itemLayout="horizontal"
                            dataSource={Object.keys(this.state.channelData)}
                            locale={{emptyText: "No channels"}}
                            renderItem={key => (
                                <List.Item actions={[
                                    <Button type="danger" shape="circle" icon="delete" onClick={this.handleChannelDelete.bind(key)} data-key={key} />,
                                    <Button type="dashed" shape="circle" icon="copy" onClick={this.handleChannelDuplicate.bind(key)} data-key={key} />,
                                    <Button type="primary" shape="circle" icon="edit" onClick={this.handleChannelEdit.bind(key)} data-key={key} />,
                                ]}>
                                    <List.Item.Meta
                                        title={this.state.channelData[key].name}
                                        description={`Dispatch date: ${this.state.channelData[key].data.dispatchDate.format('Do MMMM YYYY')}`}
                                        avatar={<Avatar size="large" icon="mail" />}
                                    />
                                </List.Item>
                            )}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="danger" htmlType="button" onClick={this.previous}>Previous</Button>
                        <Button type="primary" htmlType="button" onClick={this.saveAndContinue}>Next</Button>
                    </Col>
                </Row>

                <Modal
                    title="Add a channel to this campaign"
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.hideModal}
                    width={900}
                    maskClosable={false}
                    footer={null}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                >
                    {(() => {
                        switch(this.state.modalChannelObject.type) {
                            case 'Email':
                                return <EmailForm formData={this.state.modalChannelObject} saveChannel={this.saveChannel} hideModal={this.hideModal} />;
                            default:
                                return <p>{this.state.modalChannelObject.type} is not implemented yet</p>;
                        }
                    })()}
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ChannelSelector);
